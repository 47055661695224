<template>
    <div class="home">
        <div class="homeComAbout">
<!--            <h1 class="homeTitle center">-->
<!--                <img style="width: 60px;height: 60px;" src="@/assets/logo5.png" alt="">-->
<!--              贵州浩佑暖通制冷设备有限公司-->
<!--            </h1>-->
            <div class="banner-img">
                <img src="@/assets/content.png" alt="" style="width: 430px;height: 400px;margin-left: 100px;float: left;">
                <div style="float: left;width: 780px;margin-left: 30px;font-size: 16px;">
                    <p>电话：13595294191</p>
                    <p style="margin: 20px 0;">邮箱：546592935@qq.com
                    </p>
                    <p>地址：贵州省遵义市红花岗区忠庄街道吾悦广场步行街SY3-4-111号</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
	name: 'homePage',
	components: {
	}
}
</script>

<style lang="scss" scoped="scoped">
  .home{
    background:#fff;
  }
  .homeComAbout{
    .homeTitle{
      font-weight: 700;
      color: #303133;
      font-size: 2rem;
    }
    .titleIntroduce{
      color: #606266;
      font-size:1.1rem ;
      padding-bottom:14px;
    }
    .center{
      text-align: center;
    }
    .banner-img{
      width: 100%;
      height: 640px;
      >img{
        width: 1140px;
        height: 100%;
      }
    }
  }
</style>
